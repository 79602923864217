<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section
      class="px-4 pb-4"
      :class="{ 'pt-8': !isShowingMessage, 'pt-32': isShowingMessage }"
    >
    </tapered-section>
    <tapered-section class="px-4 pt-4 pb-8">
      <upload-appeal-document
        :application-id="applicationId"
        :upload-is-successful="disableUpload"
      />
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapGetters } from "vuex";
import { module as flashModule } from "@/store/flash";
import UploadAppealDocument from "./Appeal/UploadAppealDocument.vue";

export const translationPrefix = "view.appeal";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {},
    al: {},
  },
};
export default {
  components: {
    UploadAppealDocument,
  },
  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    disableUpload: false,
  }),
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
  },
};
</script>

<style>
</style>