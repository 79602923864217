import store from '@/store';
import {actions as authActions, module as authModule} from '@/store/auth';

export default async (to, _from, next) => {
  const isLoggedIn = store.getters[`${authModule}/isLoggedIn`];


  if (isLoggedIn) {
    await store.dispatch(`${authModule}/${authActions.fetchAuthenticatedUser}`);
  }

  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn) {
      next({
        name: "Login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
}