import { User } from '@/api';

export const module = 'auth';
export const mutations = {
  setToken: 'setToken',
  setUser: 'setUser',
};
export const actions = {
  authenticate: 'authenticate',
  signOut: 'signOut',
  forceSignOut: 'forceSignOut',
  fetchAuthenticatedUser: 'fetchAuthenticatedUser',
}

const getUserFromLocalStorage = () => {
  const localStorageUser = localStorage.getItem("user");
  let user = null;

  try {
    user = JSON.parse(localStorageUser);
  } catch {
    user = null;
  }

  return user;
};

/** @type {import('vuex').Module} */
const authModule = {
  namespaced: true,
  state: () => ({
    notifications: [],
    token: localStorage.getItem("token"),
    user: getUserFromLocalStorage(),
  }),
  getters: {
    isLoggedIn: ({ token }) => !!token,
    isUserLoaded: ({ user }) => !!user,
  },
  mutations: {
    [mutations.setToken](state, token) {
      state.token = token;
    },
    [mutations.setUser](state, user) {
      state.user = user;
    },
  },
  actions: {
    async [actions.authenticate]({ commit }, loginDetails) {
      const { data: loginResponse } = await User.login(loginDetails)

      commit(mutations.setToken, loginResponse.data.token)
      localStorage.setItem("token", loginResponse.data.token);
      if (loginResponse.data.is_temp) {
        localStorage.setItem("is_temp", '1');
      }

      const { data: userResponse } = await User.auth();
      commit(mutations.setUser, userResponse.data.user);
      localStorage.setItem("user", JSON.stringify(userResponse.data.user));

    },
    async [actions.signOut]({ commit }) {
      await User.logout();

      commit(mutations.setToken, null);
      localStorage.removeItem("token");

      commit(mutations.setUser, null);
      localStorage.removeItem("user");
    },
    async [actions.forceSignOut]({ commit }) {
      commit(mutations.setToken, null);
      localStorage.removeItem("token");

      commit(mutations.setUser, null);
      localStorage.removeItem("user");
    },
    async [actions.fetchAuthenticatedUser]({ commit }) {
        const { data: userResponse } = await User.auth();
        commit(mutations.setUser, userResponse.data.user);
        localStorage.setItem('user', JSON.stringify(userResponse.data.user));
    },
  }
}

export default authModule;
