import Vue from 'vue';
import VueRouter from 'vue-router';

import checkAuth from './checkAuth';
import scrollBehavior from './scrollBehavior';

import Home from '@v/Home.vue';
import About from '@v/About.vue';
import Login from '@v/Login.vue';
import Register from '@v/Register.vue';
import Apply from '@v/Apply.vue';
import CreateApplicationRedirecter from '@v/CreateApplicationRedirecter.vue';
import Profile from '@v/Profile.vue';
import Applications from '@v/Applications.vue';
import Questionary from '@v/Questionary.vue';
import ScannedQrVisa from '@v/ScannedQrVisa.vue';
import NotFound from '@v/NotFound.vue';
import SomethingWentWrong from '@v/SomethingWentWrong.vue';
import Payment from '@v/Payment.vue';
import Track from '@v/Track.vue';
import DownloadVisa from '@v/DownloadVisa.vue';
import Appeal from "@v/Appeal.vue";

Vue.use(VueRouter)

// meta.title is actually a vue-i18n key store in e-title

/** @type {import('vue-router').RouterOptions['routes']} */
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "default",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: route => ({ redirectToOnLogin: route.query.redirect }),
    meta: {
      layout: "default",
      title: "login",
      guestOnly: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      layout: "default",
      title: "register",
      guestOnly: true,
    }
  },
  {
    path: "/apply",
    name: "Apply",
    component: Apply,
    meta: {
      layout: "default",
      title: "apply"
    }
  },
  {
    // Must be called with ?visaTypeId=someId
    path: "/create-application",
    name: "CreateApplicationRedirecter",
    component: CreateApplicationRedirecter,
    props: route => ({ visaTypeId: route.query.visaTypeId }),
    meta: {
      layout: "default",
      authOnly: true,
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      layout: "default",
      title: "profile",
      authOnly: true,
    }
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
    props: route => ({ scrollToTrackingNumber: route.query.trackingNumber }),
    meta: {
      layout: "default",
      title: "applications",
      authOnly: true,
    }
  },
  {
    path: "/applications/:applicationId",
    name: "Questionary",
    component: Questionary,
    props: route => ({ applicationId: route.params.applicationId }),
    meta: {
      layout: "default",
      title: "questionary",
      authOnly: true,
    }
  },
  {
    path: "/applications/:applicationId/payment",
    name: "Payment",
    component: Payment,
    props: route => ({ applicationId: route.params.applicationId }),
    meta: {
      layout: "default",
      title: "payment",
      authOnly: true,
    }
  },
  {
    path: "/applications/:applicationId/appeal",
    name: "Appeal",
    component: Appeal,
    props: route => ({ applicationId: route.params.applicationId }),
    meta: {
      layout: "default",
      title: "appeal",
      authOnly: true,
    }
  },
  {
    path: "/track",
    name: "Track",
    component: Track,
    props: route => ({ preFilledTrackingNumber: route.query.trackingNumber }),
    meta: {
      layout: "default",
      title: "track",
    }
  },
  {
    path: "/download-visa",
    name: "DownloadVisa",
    component: DownloadVisa,
    props: route => ({ visaUuid: route.query.uuid }),
    meta: {
      layout: "default",
      title: "download-visa",
    }
  },
  {
    path: "/visas/:uuid/verify",
    name: "ScannedQrVisa",
    component: ScannedQrVisa,
    props: route => ({ visaUuid: route.query.uuid }),
    meta: {
      layout: "default",
    }
  },
  {
    path: "/about",
    component: About,
    meta: {
      layout: "default",
      title: "about",
    },
  },
  // TODO
  {
    path: "/woops",
    name: "SomethingWentWrong",
    component: SomethingWentWrong,
    meta: {
      layout: "default",
    }
  },
  {
    name: "NotFoud",
    path: "/:path(.*)",
    component: NotFound,
    meta: {
      layout: "default",
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

router.beforeEach(checkAuth);

export default router
