<template>
  <div>
    <loader
      v-if="isLoadingAppealDocumentDetail"
      :loading="isLoadingAppealDocumentDetail"
      size="1.5rem"
      margin="4px"
    />
    <article
      v-else
      class="border border-gray-400 rounded-md p-4 md:p-8 flex flex-wrap items-start bg-white"
    >
      <div class="w-full md:w-1/2 pt-16 pb-4 lg:px-8 lg:pb-8">
        <header class="mb-4">
          <h3>
            {{ $t(`${$options.tPfx}.appealRejection`) }}
          </h3>
        </header>
        <div>
          <file-input
            deletable
            accept="application/pdf, image/jpeg, image/png"
            file-name="appealDocument"
            :max-size="31457280"
            :disabled="disabled"
            :label="$t(`${$options.tPfx}.appealDocument`)"
            :upload-hint="$t(`${$options.tPfx}.uploadHint`)"
            :upload-url="uploadUrl"
            :delete-url="deleteUrl"
            :origin="documentOrigin"
            :headers="authorizationHeader"
            :reduce-response="(response) => response.data.data.appealDocument"
            :error="localizedError"
            :value="documentOfAppeal"
            @input="onDocumentAdded"
            @upload-error="toDo"
            @deleted="onDocumentRemoved"
            @delete-error="toDo"
            @file-size-error="fileError = 'maxSizeError'"
            @file-type-error="fileError = 'onlyValidFormat'"
            class="mb-2"
          />
        </div>
        <button
          @click="finalizeAppeal"
          :disabled="disabled"
          class="button button-secondary w-full md:w-auto"
        >
          {{ $t(`${$options.tPfx}.finalizeAppeal`) }}
        </button>
        <application-links class="mt-10" :application-id="applicationId" />
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { module as authModule } from "@/store/auth";
import { Application } from "@/api";
import ApplicationLinks from "@v/Payment/ApplicationLinks.vue";

export const translationPrefix = "view.appeal.uploadAppealDocument";

export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      appealDocument: "Appeal document",
      uploadHint: "Select or drop appeal document",
      onlyValidFormat: "Only pdf, jpeg or png format is accepted",
      maxSizeError: "Max size is 30MB",
      isRequired: "Appeal document is required",
      localizedFileName: "Document of Appeal",
      finalizeAppeal: "Finalize appeal",
      appealRejection: "Here you can appeal the rejection of the application"
    },
    al: {
      appealDocument: "Dokumenti i apelimit",
      uploadHint: "Zgjidh ose tërhiq dokumentin e apelimit",
      onlyValidFormat: "Pranohet vetëm formati pdf, jpeg ose png",
      maxSizeError: "Madhësia maksimale është 30MB",
      isRequired: "Dokumenti i apelimit është i detyruar",
      localizedFileName: "Dokumenti i apelimit",
      finalizeAppeal: "Finalizo apelimin",
      appealRejection: "Këtu mund të apeloni vendimin e refuzimit të aplikimit"
    },
  },
};


export default {
  components: {ApplicationLinks},
  tPfx: translationPrefix,
  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },
    uploadIsSuccessful: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isLoadingAppealDocumentDetail: false,
    data: {},
    fileError: null,
  }),
  computed: {
    ...mapState(authModule, ["token"]),
    authorizationHeader() {
      return {
        Authorization: `Bearer ${this.token}`,
      };
    },
    disabled() {
      return (
        this.uploadIsSuccessful
      );
    },
    documentOrigin() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    uploadUrl() {
      return `${this.documentOrigin}/me/applications/${this.applicationId}/appeal`;
    },
    deleteUrl() {
      return `${this.documentOrigin}/me/applications/${this.applicationId}/appeal`;
    },
    localizedError() {
      if (!this.fileError) {
        return null;
      }
      return this.$t(`${this.$options.tPfx}.${this.fileError}`);
    },
    documentOfAppeal() {
      if (!this.data.appealDocument) {
        return null;
      }

      const { name, ...file } = this.data.appealDocument;
      file.name = name[this.$i18n.locale];

      return file;
    },
  },
  methods: {
    async handleLoadAppealDocumentDetailErrors(error) {
      switch (error.response.status) {
        case 404:
          return this.$router.replace({ name: "NotFound" });
        case 500:
          return this.$router.replace({ name: "SomethingWentWrong" });
        case 409:
        case 403:
          return this.$router.push({ name: "Applications" });
      }
    },
    async loadAppealDetails() {
      this.isLoadingAppealDocumentDetail = true;

      try {
        await Application.getAppealDetails(this.applicationId).then((response) => {
          this.data = response.data.data;
          console.log(response);
        })
      } catch (error) {
        await this.handleLoadAppealDocumentDetailErrors(error);
      } finally {
        this.isLoadingAppealDocumentDetail = false;
      }
    },
    // TODO: Implete logic for handling upload and delete failure
    toDo() {
      console.log("upload or delete failed");
    },
    onDocumentAdded(documentOfAppeal) {
      this.fileError = null;

      this.data.appealDocument = documentOfAppeal;
    },
    onDocumentRemoved() {
      this.data.appealDocument = null;
    },

    async finalizeAppeal() {
      if (!this.documentOfAppeal) {
        this.fileError = "isRequired";
        return;
      }

      await Application.finalizeAppeal(this.applicationId);
      await this.$router.replace({ name: "Applications" });
    },
  },
  async mounted() {
    await this.loadAppealDetails();
  },
};
</script>

<style>
</style>
