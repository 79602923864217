const STATUS_ENUM = {
  draft: 'DRAFT',
  open: 'OPEN',
  consularInProgress: 'CONSULAR_IN_PROGRESS',
  consularNeedChanges: 'CONSULAR_NEED_CHANGES',
  waitingForPayment: 'WAITING_FOR_PAYMENT',
  waitingFerPayment: 'WAITING_FER_PAYMENT',
  verifyPayment: 'VERIFY_PAYMENT',
  unpaid: 'UNPAID',
  paid: 'PAID',
  ferPaid: 'FER_PAID',
  rejected: 'REJECTED',
  dckInProgress: 'DCK_IN_PROGRESS',
  verificationInProgress: 'VERIFICATION_IN_PROGRESS',
  shishInProgress: 'SHISH_IN_PROGRESS',
  pkInInProgress: 'PK_IN_IN_PROGRESS',
  shishApproved: 'SHISH_APPROVED',
  pkApproved: 'PK_APPROVED',
  shishRejected: 'SHISH_REJECTED',
  pkRejected: 'PK_REJECTED',
  verifiedByShishPk: 'VERIFIED_BY_SHISH_PK',
  verifiedByMinistry: 'VERIFIED_BY_MINISTRY',
  approved: 'APPROVED',
  printedGenerated: 'PRINTED_GENERATED',
  notShowedUp: 'NOT_SHOWED_UP',
  appealed: 'APPEALED',
  reapplication: 'REAPPLICATION',
  canceled: 'CANCELED',
};

export default STATUS_ENUM;
